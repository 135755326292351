import React from "react";
import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import pepijn from "../img/pepijn.jpg";
import joke from "../img/joke.jpg";
import jonathan from "../img/jonathan.jpg";
import ashmita from "../img/ashmita.jpg";

// eslint-disable-next-line
export default () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "partners.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const sprekers = [
    {
      img: pepijn,
      name: "Pepijn Keppel",
      subtitle: "Ex-tophockeyer",
      body: "Pepijn Keppel (1996) is schrijver, journalist voor De Groene Amsterdammer en columnist voor Trouw. Vorig jaar debuteerde de ex-profhockeyer met 'De laatste man', een boek over Pepijns eenzame zoektocht naar zijn seksuele identiteit, jezelf worden en losbreken van je omgeving. Hiervoor is behoorlijk wat Dorst nodig, een thema dat centraal zal staan deze avond.",
    },
    {
      img: joke,
      name: "Joke Boonstra",
      subtitle: "Rotterdamse Zakenvrouw van 2022",
      body: "Joke Boonstra, vice-voorzitter Raad van Bestuur van het Erasmus MC en Rotterdamse zakenvrouw van het jaar 2022, zal voor ons spreken over haar rol als bestuurder van het grootste ziekenhuis van Nederland. Zij maakt belangrijke thema’s bespreekbaar in de zorg, zo ook het thema diversiteit en inclusie. Met een beetje Rotterdams durf en lef kun je een hoop bereiken!",
    },
    {
      img: ashmita,
      name: "Ashmita Krishna Sharma",
      subtitle: "DGI-adviesbureau Sparkling Gems",
      body: "Niet bang om de status quo te bevragen en haar stem te laten horen, dat is Ashmita. Ze is op een drievoudige missie: die van representatie, gelijkwaardige kansen en veranderaar naar een inclusieve werkvloer. Ashmita zit in Diversiteit, Gelijkwaardigheid en Inclusie (DGI) als changemaker. Als vrouw met verschillende culturele identiteiten is de rode draad in haar leven altijd geweest: hoe ben ik mijn authentieke zelf en kan ik ruimte creëren voor anderen zodat zij dat ook kunnen zijn? Ashmita is een DGI-expert en strategist die meer dan 10 jaar aan ervaring heeft in het werken met en voor grote organisaties zoals Shell, Deloitte en Mediq in verandermanagement. Daarnaast is ze de oprichtster van DGI-adviesbureau Sparkling Gems.",
    },
    {
      img: jonathan,
      name: "Jonathan Martinez",
      subtitle: "Stichting Waarheid",
      body: "In Rotterdam belandde hij onterecht in de jeugdgevangenis. Samen met zijn maatschappelijk werkster zorgde hij ervoor dat zijn onschuld werd bewezen en dat hij weer aansluiting kon vinden in de maatschappij. Jonathan heeft zich daarna voorgenomen om een constructieve bijdrage te leveren aan de maatschappij. Het is Jonathan gelukt om deze positieve omslag in zijn leven te bewerkstelligen, en nu heeft hij er zijn levenswerk van gemaakt om anderen hierbij te helpen. Om dit te kunnen doen heeft hij Stichting Waarheid opgericht. ",
    },
  ];

  const heroImage = getImage(data.file);

  return (
    <Layout>
      <FullWidthImage
        img={heroImage}
        title={"Symposium"}
        subheading={"14 april, 18:30 - 22:00, Sociëteit aan de Maas"}
        height={"70vh"}
        color="#E4DFCD"
        darken={true}
      />
      <section
        className="section section--gradient"
        style={{ background: "#004B5F" }}
      >
        <div className="container" id="main">
          <div className="section content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12 promote">
                      <p className="has-text-centered">
                        14 april staat een prachtig evenement op de planning:
                        het Lustrum Symposium. Deze zal plaatsvinden in de
                        Sociëteit aan de Maas vanaf 18:30 tot 22:00, met
                        aansluitend een borrel.
                      </p>
                      <Link
                        to="https://store.ticketing.cm.com/lustrumsymposium"
                        rel="noopener"
                        target="_blank"
                        className="promote-button center"
                        style={{
                          backgroundColor: "#e3decc",
                          color: "#00412d",
                          margin: "2rem",
                        }}
                      >
                        KOOP HIER UW KAARTEN!
                      </Link>
                      <p className="has-text-centered">
                        Het Lustrum Symposium is een avondvullend programma in
                        het thema “Waar staan wij over 5 jaar?”, waarbij 4
                        verschillende sprekers over hun eigen verandering zullen
                        vertellen. Het gaat over hoe tot individuele verandering
                        en collectieve verandering te komen. Kortom, deze avond
                        kan zowel nuttig zijn voor uw eigen ontwikkeling als
                        bepalend zijn voor de ontwikkeling en de richting van de
                        samenleving in de toekomst.
                      </p>
                      <h3 className="has-text-centered">
                        De volgende sprekers zullen aanwezig zijn:
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-multiline">
              {sprekers.map((spreker, i) => (
                <div className="is-parent column is-3" key={i}>
                  <article className={`blog-list-item tile is-child`}>
                    <header style={{ justifyContent: "normal", gap: "1rem" }}>
                      <img
                        src={spreker.img}
                        alt={spreker.name}
                        style={{ width: "100%" }}
                      />

                      <p className="post-meta">
                        <span className="title" style={{ fontSize: "43px" }}>
                          {spreker.name}
                        </span>
                        <span className="subtitle margin-top-0 is-block">
                          “{spreker.subtitle}”
                        </span>
                        <span className="body">{spreker.body}</span>
                      </p>
                    </header>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
